div.pn-acknowledgement-modal {
  .lmn-modal-content {
    .lmn-modal-header {
      font-size: 1.2rem;
      padding-right: 0;
      border-bottom: none !important;

      div.lmn-modal-title {
        font-size: 1.2rem;
        margin: 0;
      } 

      p {
        font-weight: bold;
        font-size: 1.2rem;
        margin: 0;
        text-align: center;
      }
    }

    .lmn-modal-body {
      text-align: center !important;
      margin: 0;
      padding: 0;
      line-height: 20px;
    }

    .lmn-modal-footer {
      justify-content: center !important;
    }
  }
}
