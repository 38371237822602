div.biometrics-modal.lmn-modal-wrap {
  .lmn-modal {
    min-width: auto;

    .lmn-modal-content {
      .lmn-modal-header {
        font-size: 1.2rem;
        padding-right: 0;

        div.lmn-modal-title {
          font-size: 1.2rem;
          margin: 0;
        }

        img {
          width: 50px;
        }

        p {
          font-weight: bold;
          font-size: 1.2rem;
          margin: 0;
        }
      }

      .lmn-modal-body {
        text-align: center;
        margin: 0;
        padding: 0;
        line-height: 20px;
      }

      .lmn-modal-footer {
        justify-content: center;
      }

      .react-container {
        padding: 0;
      }
    }
  }

  .biometrics-content {
    font-size: 1rem;
    white-space: pre-line;
  }

  .biometrics-content-loading {
    height: 50px;
  }
}

div.biometrics-modal.biometrics-custom-title {
  top: 7rem !important;
  align-items: start !important;
  .lmn-modal-content {
    padding: 1.5rem;
    .lmn-modal-header {
      padding: unset !important;
      margin: unset !important;
      padding-right: 0;
      margin: 0;
      margin-bottom: 1rem;
      border-bottom: none;
      justify-content: center;

      .biometrics-header-with-img {
        width: 100%;
        text-align: center;

        img {
          width: 100px;
        }
      }
    }
    .lmn-modal-body {
      .react-container .action-content {
        .cbusol-input-group {
          margin-top: 10px;
        }
        .action-code-expires {
          margin: unset !important;
        }
        .phone-otp-footer {
          margin-bottom: unset;
        }
      }
      // padding: 0;
      // margin: 0;
    }

    .lmn-modal-footer {
      border-top: none;
      margin: unset !important;
    }
  }
}

div.biometrics-modal.biometrics-null-title {
  .lmn-modal-content {
    .lmn-modal-header {
      margin: 0;
      border-bottom: none;
    }
  }
}
